






























































import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Vuelidate from 'vuelidate';
import {helpers, required} from 'vuelidate/lib/validators';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';

Vue.use(Vuelidate);
const currency = helpers.regex('currency', /^\d+(\.\d{1,2})?$/);
const moreThan = (param) => (value) => value >= param;
const lessThan = (param) => (value) => value <= param;

@Component({
  components: {Modal},
  validations: {
    form: {
      subscriptionTrialPrice: {
        currency,
        moreThan: moreThan(0.99),
        lessThan: lessThan(19.99),
      },
      subscriptionMonthlyPrice: {
        required,
        currency,
        moreThan: moreThan(4.99),
        lessThan: lessThan(49.99),
      },
      subscriptionYearlyPrice: {
        required,
        currency,
        moreThan: moreThan(49.99),
        lessThan: lessThan(299.99),
      },
    },
  },
})
export default class EditModelSubscription extends Vue {
  user: ModelDataInterface = vxm.user.data;
  showModal = false;
  isRequestSend = false;
  form = {
    subscriptionTrialPrice: this.user.subscriptionTrialPrice,
    subscriptionMonthlyPrice: this.user.subscriptionMonthlyPrice,
    subscriptionYearlyPrice: this.user.subscriptionYearlyPrice,
  };

  save() {
    this.$v.form.$touch();
    if (!this.$v.form.$invalid && !this.isRequestSend) {
      this.isRequestSend = true;
      const data = {user: {...this.form, _id: this.user._id}};
      vxm.user
        .updateProfile(data)
        .then(() => {
          this.showModal = false;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }
}
