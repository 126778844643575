














import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';

@Component({
  components: {Modal},
})
export default class EditModelHashKey extends Vue {
  showModal = false;

  save() {
    this.showModal = false;
  }
}
