

















































import {Component, Vue} from 'vue-property-decorator';
import EditModelSubscription from '@/components/modals/EditModelSubscription.vue';
import EditModelHashKey from '@/components/modals/EditModelHashKey.vue';
import Accordion from '@/components/Accordion.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import ContactUsModalNew from '@/components/modals/ContactUsModalNew.vue';

@Component({
  components: {Accordion, EditModelSubscription, EditModelHashKey, ContactUsModalNew},
})
export default class ModelProfileSubscription extends Vue {
  isTrialEnabled = false;
  isRequestSend = false;
  showContactUs = false;

  mounted() {
    this.isTrialEnabled = vxm.user.data.subscriptionTrialEnabled;
  }

  get user(): ModelDataInterface {
    return vxm.user.data;
  }

  enableTrial() {
    this.isTrialEnabled = !this.isTrialEnabled;
    if (!this.isRequestSend) {
      this.isRequestSend = true;
      const data = {user: {subscriptionTrialEnabled: this.isTrialEnabled, _id: this.user._id}};
      vxm.user
        .updateProfile(data)
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }
  logout() {
    this.$store.dispatch('resetStore').then(() => {
      localStorage.removeItem('model');
      localStorage.removeItem('fan');
      this.$socket.disconnect();
      this.$router.push('/login');
    });
  }
}
